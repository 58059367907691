





























































import { BlogPost } from '@/models/blog/blogPost';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CommonGetters } from '@/store/modules/common/enums';
import { blogService } from '@/services/blog/blog.service';
import { msalPluginInstance } from '@/plugin/MsalPlugin';
import { Routes } from '@/router/routes';

@Component
export default class BlogPostContent extends Vue {
  protected loaded = false;
  public blogPost: BlogPost = new BlogPost();

  @Watch('language')
  public async loadBlogPostContent(): Promise<void> {
    this.loaded = false;
    await blogService.getBlogPost(this.$route.params.id).then((p) => {
      this.blogPost = p;
      this.loaded = true;
    });
  }

  public async created(): Promise<void> {
    if (!this.loaded) await this.getBlogPost();
  }

  private async getBlogPost(): Promise<void> {
    await this.loadBlogPostContent();
  }

  public backRoute(): string {
    if (msalPluginInstance.isUserAuthenticated()) return Routes.Customer;
    return Routes.Home;
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }
}
