









import { AuthActions } from '@/store/modules/auth/enums';
import { Component, Vue } from 'vue-property-decorator';
import { MediasActions } from '@/store/modules/medias/enums';
import { MerchantConfigActions } from '@/store/modules/merchant-config/enums';
import { MerchantMigrationActions } from '@/store/modules/merchant-migration/enums';
import { Routes } from '@/router/routes';
import { WebPaymentsActions } from '@/store/modules/web-payments/enums';
import BlogPostContent from '@/views/blog/BlogPostContent.vue';
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import HeaderBackground from '@/components/layout/HeaderBackground.vue';

@Component({
  components: {
    'ck-blog-post-content': BlogPostContent,
    'ck-footer': Footer,
    'ck-header': Header,
    'ck-header-background': HeaderBackground,
  },
})
export default class BlogPost extends Vue {
  protected show = true;

  account(): void {
    this.$router.push({
      name: Routes.Customer,
      params: { loaded: 'true', showAccount: 'true' },
    });
  }

  async logout(): Promise<void> {
    this.show = false;
    this.$spinner.showSpinner();
    this.$store.dispatch(MerchantConfigActions.Clear);
    this.$store.dispatch(MediasActions.Clear);
    this.$store.dispatch(WebPaymentsActions.Clear);
    this.$store.dispatch(MerchantMigrationActions.Clear);
    this.$store.dispatch(AuthActions.Logout);
    await this.$msal.signOut();
  }
}
